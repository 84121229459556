import * as React from "react"
import { Frame, useCycle } from "framer"

// 圖像列表
const images = [
    "https://photoshopvip.net/wp-content/uploads/2022/06/figma-plugins-for-design-systems-cvoer2-1.jpg",
    "https://photoshopvip.net/wp-content/uploads/2018/11/best-of-museum-for-free-821x513.jpg",
]

function RandomHeroImage() {
    // 從圖像數組中隨機選擇一個
    const randomImage = images[Math.floor(Math.random() * images.length)]

    return <Frame background={`url(${randomImage})`} size={"100%"} />
}

export default RandomHeroImage
